import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { StatusService } from 'src/app/services/status.service';
import { NavController, ModalController } from '@ionic/angular';
import { ClienteNewModal } from 'src/app/modals/cliente-new/cliente-new.modal';
import { NotifyService } from 'src/app/services/notify.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ClienteFilterModal } from 'src/app/modals/cliente-filter/cliente-filter.modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-appalti-editclient',
  templateUrl: './appalti-editclient.component.html',
  styleUrls: ['./appalti-editclient.component.scss'],
})
export class AppaltiEditclientComponent implements OnInit {

  public items = [];
  public searchString: string;
  public isLoading = false;

  @Input() selected;
  @Output() selectedChange = new EventEmitter<any>();
  @Output() clientEdited = new EventEmitter<any>();

  constructor(
    public api: JsonapiService,
    private status: StatusService,
    private modalController: ModalController,
    private notify: NotifyService,
    public loadingService: LoadingService,
    private translate: TranslateService
  ) {
    this.searchString = this.status.getAppaltiCustomerSearchString();
    // this.refreshList();
  }

  ngOnInit() {
    this.status.setAppaltiClientOrder('rag_soc');
    this.searchString = '';
    this.search();
  }

  search() {
    // console.log('search', this.searchString);
    this.status.setAppaltiCustomerSearchString(this.searchString);
    this.refreshList();
  }

  public refreshList(emit = true) {
    return new Promise(resolve => {
      this.loadingService.start();
      this.isLoading = true;
      this.api.getCollection(
        'customers',
        this.status.getAppaltiClientFilter(),
        this.status.getAppaltiClientOrder()
      ).subscribe((customers: any) => {
        this.loadingService.stop();
        this.isLoading = false;
        if (customers && customers.data) {
          this.items = customers.data;
        }
        if (emit) {
          if (this.selected) {
            // console.log('this.selected', this.selected);
            this.selected = this.items.find(x => x.id === this.selected.id);
            this.selectedChange.emit(this.selected);
          }
        }
        if (emit) {
          const newAppaltoCliente = this.status.getNewAppaltoCliente();
          if (newAppaltoCliente) {
            this.selected = this.items.find(x => x.id === newAppaltoCliente.id); // TODO check se seleziona bene (non sono gli stessi oggetti)
            this.selectedChange.emit(this.selected);
          }
        }
        resolve();
      }, (error) => {
        resolve();
        this.api.handlingError(error);
      });
    });
  }

  addCliente() {
    this.openCliente(null);
  }

  openFilter() {
    this.modalController.create({
      component: ClienteFilterModal
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(res => {
        this.refreshList();
      });
    });

  }

  openCliente(item) {

    this.modalController.create({
      component: ClienteNewModal
    }).then(modal => {
      this.status.selectedClient = item;
      modal.present();
      modal.onDidDismiss().then(res => {
        if (res.data) {
          this.translate.get(
            ['CUSTOMERS.update', 'CUSTOMERS.added']
          ).subscribe(async translations => {
            if (item) {
              this.notify.toast(translations['CUSTOMERS.update'], 'success');
            } else {
              this.notify.toast(translations['CUSTOMERS.added'], 'success');
            }
            await this.refreshList(false);
            const selected = this.items.find(x => x.id === res.data.id);
            this.clientEdited.emit(selected);
        });
        }

      });
    });
  }

  selectCliente(item: any) {
    this.selected = item;
    this.selectedChange.emit(this.selected);
  }

}
