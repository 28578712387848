import { Component, OnInit, Input, Output } from '@angular/core';
import { config } from 'src/app/general/config';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-invio-item',
  templateUrl: './invio-item.component.html',
  styleUrls: ['./invio-item.component.scss'],
})
export class InvioItemComponent implements OnInit {

  @Input() item: any;

  public timeFormat = config.timeFormat;
  public timeLocale = 'en';

  constructor(
    private translate: TranslateService
  ) { }

  public dateFrom(d) {
    const m = moment(d).toDate();
    console.log('-----------: InvioItemComponent -> dateFrom -> m', m);
    return m;
  }

  ngOnInit() {
    console.log('+++', this.item);
    this.timeLocale = this.translate.currentLang;
  }

}
