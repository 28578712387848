import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavParams } from '@ionic/angular';
import { MomentModule } from 'ngx-moment';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { LocalStorageService } from 'ngx-store';
import { OauthService } from './services/oauth.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MessageService } from './services/MessageService.service';
import { JsonapiService } from './services/jsonapi.service';
import { LocalJsonService } from './services/local.json.service';
import { DownloadService } from './services/Download.service';
import { SocketService } from './services/socket.service';
import { StatusService } from './services/status.service';
import { favoritesService } from './services/favorites.service';
import { StorageService } from './services/storage.service';
import { FileService } from './services/file.service';
import { TabPreferitiPageModule } from './pages/tabPreferiti/tabPreferiti.module';
import { ComponentsModule } from './components/components.modules';
import { File } from '@ionic-native/file/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UtilsService } from './services/utils.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MomentModule,
    TabPreferitiPageModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }
    )
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    TokenService,
    LocalStorageService,
    OauthService,
    HttpClient,
    MessageService,
    JsonapiService,
    LocalJsonService,
    DownloadService,
    SocketService,
    StatusService,
    favoritesService,
    StorageService,
    FileService,
    UtilsService,
    File,
    Zip,
    FileOpener,
    NavParams
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
