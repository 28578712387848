import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/general/config';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-prodotti-item',
  templateUrl: './prodotti-item.component.html',
  styleUrls: ['./prodotti-item.component.scss'],
})
export class ProdottiItemComponent implements OnInit {

  @Input() item: any;
  @Input() selectable = false;
  @Input() isSelected = false;
  @Input() checkApplicator = false;

  @Output() selectedChange = new EventEmitter();
  @Output() detailClick = new EventEmitter();

  public timeFormat = config.timeFormat;
  public timeLocale = 'en';

  constructor(
    public apiService: JsonapiService,
    public notify: NotifyService,
    private translate: TranslateService
  ) { }


  public dateFrom(d) {
    return moment(d).toDate();
  }

  ngOnInit() {
    this.timeLocale = this.translate.currentLang;
  }

  selectItem($event: any) {
    $event.preventDefault();
    this.selectedChange.emit($event.detail.checked);
  }

  clickItem($event: any) {
    $event.preventDefault();
    if (this.selectable) {
      this.isSelected = !this.isSelected;
      // this.selectedChange.emit(this.isSelected);
    }
    else {
      this.open();
    }
  }

  public open() {
    this.detailClick.emit();
  }

  public add($event: any) {
    $event.preventDefault();
    const apiObj = { data: { type: 'applicator-products', attributes: { products: [this.item.sku] } } };

    this.apiService.postEntity('applicator-products', apiObj, 'import').subscribe((res: any) => {
      if ((res) && (res.data) && (res.data.attributes) && (res.data.attributes.imported)) {
        this.item.isApplicator = true;
        this.translate.get('PRODUCTS.added', { productName: this.item.name }).subscribe((tran) => {
          this.notify.toast(tran, 'success');
        })
      }
      else {
        this.translate.get('PRODUCTS.added_denied', { productName: this.item.name }).subscribe((tran) => {
          this.notify.toast(tran, 'warning');
        })
      }
    }, (error) => { this.apiService.handlingError(error); });
  }

}
