import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { IUser, JsonApiData } from 'src/models/user.model';
import { NotifyService } from 'src/app/services/notify.service';
import { StatusService } from 'src/app/services/status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {

  public soggetto;
  public descrizione;
  public filename;
  public selectedTopic;

  public topics = [];

  constructor(
    private modalCtrl: ModalController,
    private api: JsonapiService,
    private status: StatusService,
    private notify: NotifyService,
    private translate: TranslateService
  ) {
    this.translate.get(['SUPPORT.noproducts', 'SUPPORT.nomaker', 'SUPPORT.generic']).subscribe(trans => {
      this.topics = [trans['SUPPORT.noproducts'], trans['SUPPORT.nomaker'], trans['SUPPORT.generic']];
    });
  }

  ngOnInit() {
  }

  isValid() {
    return this.descrizione && this.soggetto && this.selectedTopic;
  }

  addFile() {
    // TODO add file
    console.log('-----------: ContactsComponent -> addFile -> addFile');
    this.filename = 'filename.ext';
  }

  send() {
    this.status.currentUser().subscribe((usrData: JsonApiData) => {
      const jsonObj = {
        data: {
          type: 'ticket',
          attributes: {
            date_send: new Date(),
            email: usrData.attributes.email,
            shop_id: '1', // TODO check shop value
            user_id: usrData.id,
            soggetto: this.soggetto,
            tipologia: this.selectedTopic,
            descrizione: this.descrizione,
          }
        }
      };
      this.api.postEntity('ticket', jsonObj).subscribe((resp) => {
        this.close(true);
      }, (error) => { this.api.handlingError(error); });
    });
  }

  close(sent = false) {
    this.modalCtrl.dismiss(sent);
  }

}
