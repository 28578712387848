import { Component, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { StatusService } from 'src/app/services/status.service';
import { EventEmitter } from 'events';
import { config } from 'src/app/general/config';

@Component({
  selector: 'app-cliente-filter',
  templateUrl: './cliente-filter.modal.html',
  styleUrls: ['./cliente-filter.modal.scss'],
})
export class ClienteFilterModal implements OnInit {


  public selectedOrder;
  public selectedType;
  public orders = config.clients.orders;
  public types = config.clients.types;

  constructor(
    private modalCtrl: ModalController,
    private status: StatusService) { }

  ngOnInit() {
    if (this.status.getAppaltiClientType()) {
      this.selectedType = this.status.getAppaltiClientType();
    }
    if (this.status.getAppaltiClientOrder()) {
      this.selectedOrder = this.status.getAppaltiClientOrder();
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.back();
  }

  apply() {
    this.status.setAppaltiClientOrder(this.selectedOrder);
    this.status.setAppaltiClientType(this.selectedType);
    this.back();
  }
}
