import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorResponce } from 'src/models/jsonapi.model';
import { NotifyService } from './notify.service';
import { NavController } from '@ionic/angular';
import { LoadingService } from './loading.service';
import { TranslateService } from '@ngx-translate/core';

export interface Filter {
  name: string;
  value: any;
}


@Injectable()
export class JsonapiService {

  API_BASE: string = environment[environment.env].base_url + '/' + environment[environment.env].version;
  private translations = [];

  constructor(
    private injector: Injector,
    // tslint:disable-next-line: no-shadowed-variable
    private AuthService: AuthService,
    private notify: NotifyService,
    private navCtrl: NavController,
    private loadingSErvice: LoadingService,
    private translate: TranslateService
  ) {
    this.translate.get(['ERRORS.0', 'ERRORS.422', 'ERRORS.404', 'ERRORS.401', 'ERRORS.500']).subscribe(t => { this.translations = t; })

  }

  public getCollectionPaginated(resource: string, page: number = 1, pageSize: number = 20, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page[number]', page.toString());
    payload = payload.append('page[size]', pageSize.toString());

    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  public customGetRequest($route) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${$route}`, options);
  }

  // public resetPassword(emailAddress: string) {
  //   const uri: string = environment[environment.env].base_url + '/auth/reset-password-request';
  //   const payload =
  //   {
  //     email: emailAddress
  //   };
  //   return this.injector.get(HttpClient).post(uri, payload);
  // }

  public recoverPassword(emailAddress: string) {
    const uri: string = environment[environment.env].base_url + '/' + environment[environment.env].version + '/recover-password';
    const payload = {
      "data": {
        "type": "recover-password",
        "attributes": {
          "email": emailAddress
        }
      }
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }

  public checkCode(emailAddress: string, otpCode: string) {
    const uri: string = environment[environment.env].base_url + '/' + environment[environment.env].version + '/check-code';
    const payload = {
      "data": {
        "type": "recover-password",
        "attributes": {
          "email": emailAddress,
          "code": otpCode
        }
      }
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }

  public updatePassword(emailAddress: string, otpCode: string, newPassword: string) {
    const uri: string = environment[environment.env].base_url + '/' + environment[environment.env].version + '/update-password';
    const payload = {
      "data": {
        "type": "recover-password",
        "attributes": {
          "email": emailAddress,
          "code": otpCode,
          "password": newPassword
        }
      }
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }

  public getCollection(resource: string, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }


  public getEntity(resource: string, id) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}/${id}`, options);
  }


  public updateEntity(resource: string, id, data) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).patch(`${this.API_BASE}/${resource}/${id}`, data, options);
  }


  public postEntity(resource: string, data, addictionalPath?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    let uri = '';
    if (addictionalPath) {
      uri = `${this.API_BASE}/${resource}/${addictionalPath}`;
    } else {
      uri = `${this.API_BASE}/${resource}`;
    }

    return this.injector.get(HttpClient).post(uri, data, options);
  }


  public postEntityCustomPath(data, path?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = `${this.API_BASE}/${path}`;
    return this.injector.get(HttpClient).post(uri, data, options);
  }

  public deleteEntity(resource: string, id) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = `${this.API_BASE}/${resource}/${id}`;
    return this.injector.get(HttpClient).delete(uri, options);
  }

  public handlingError(error: ErrorResponce) {
    console.error(error);

    this.loadingSErvice.stop();

    switch (error.status) {
      case 0:
        this.notify.toast(error.status.toString() + ' - ' + this.translations['ERRORS.0'], 'danger');
        // setTimeout(() => { this.router.navigate(['logout']); }, 1500);
        break;
      case 500:
        this.notify.toast(error.status.toString() + ' - ' + this.translations['ERRORS.500'], 'danger');
        //   setTimeout(() => { this.router.navigate(['logout']); }, 1500);
        break;
      case 401:
        this.notify.toast(error.status.toString() + ' - ' + this.translations['ERRORS.401'], 'danger');
        // redirect
        this.AuthService.logoutNow();
        this.navCtrl.navigateRoot('login');
        break;
      case 404:
        this.notify.toast(this.translations['ERRORS.404'], 'danger');
        break;
      case 505:
        this.notify.toast(this.translations['ERRORS.505'], 'danger');
        break;
      case 412: // eventualmente diversificare qui.
        this.notify.toast(this.translations['ERRORS.412'], 'danger');
        break;
      case 422: // eventualmente diversificare qui.
        this.notify.toast(this.translations['ERRORS.422'], 'danger');
        break;
      default:
        this.notify.toast(this.translations['ERRORS.default'], 'danger');
        break;
    }
  }
}
