import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-prodotti-new',
  templateUrl: './prodotti-new.modal.html',
  styleUrls: ['./prodotti-new.modal.scss'],
})
export class ProdottiNewModal implements OnInit {
  productForm: FormGroup;
  validationMessages = {
    name: [
      { type: 'required', message: 'NEWPRODUCT.name_required' },
    ],
    manufacturer: [
      { type: 'required', message: 'NEWPRODUCT.manufacturer_required' },
    ],
    sku: [
      // { type: 'required', message: 'NEWPRODUCT.sku_required' }
    ],
    dialer: [
      //   { type: 'required', message: 'NEWPRODUCT.dialer_required' },
    ],
  };


  constructor(
    private modalCtrl: ModalController,
    private apiService: JsonapiService,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      manufacturer: new FormControl('', Validators.required),
      // sku: new FormControl('', Validators.required),
      sku: new FormControl(''),
      // dialer: new FormControl('', Validators.required)
      dialer: new FormControl('')
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit(obj: any) {
    this.validateAllFormFields(this.productForm);
    if (this.productForm.valid) {
      const data = {
        data: {
          type: 'products',
          attributes: {
            name: obj.name,
            manufacturer: obj.manufacturer,
            sku: (obj.sku) ? obj.sku : '-',
            dialer: (obj.dialer) ? obj.dialer : '-'
          }
        }
      };
      this.apiService.postEntity('products', data, 'suggest').subscribe(res => {
        this.close(true);
      }, (error) => { this.apiService.handlingError(error); });
    }
  }

  close(sent = false) {
    this.modalCtrl.dismiss(sent);
  }
}
