import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private currentLoader = null;
    private counter = 0;

    constructor(
        public loadingController: LoadingController,
        private translate: TranslateService
    ) {
    }

    private async createLoader() {
        const t = await this.translate.get('COMMON.wait').toPromise();
        this.currentLoader = await this.loadingController.create({
            spinner: 'dots',
            message: t,
            translucent: true,
            cssClass: 'custom-loader'
        });

    }

    public async start() {
        if (this.counter === 0) {
            await this.createLoader();
            this.currentLoader.present();
        }
        this.counter++;
    }

    public async stop() {
        if (this.counter > 0) {
            this.counter--;
        }
        if (!this.counter && this.currentLoader) {
            // console.log('dismiss', this.counter, this.currentLoader);
            this.currentLoader.dismiss();
        }
        else {
            // console.log('else', this.counter, this.currentLoader);
        }
    }
}
