import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { StatusService } from 'src/app/services/status.service';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { config } from 'src/app/general/config';
import { LoadingService } from 'src/app/services/loading.service';
import { ProdottiFilterComponent } from '../prodotti-filter/prodotti-filter.component';
import { ProdottiNewModal } from 'src/app/modals/prodotti-new/prodotti-new.modal';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-appalti-editproducts',
  templateUrl: './appalti-editproducts.component.html',
  styleUrls: ['./appalti-editproducts.component.scss'],
})
export class AppaltiEditproductsComponent implements OnInit {

  public timeFormat = config.timeFormat;


  @Input() selected = [];
  @Output() selectedChange = new EventEmitter<any>();


  public items = [];
  public PAGE_SIZE = 20;

  private currentPage = 1;
  public remaining = 20;

  private calledGeneric = false;
  private applicatorItems = [];

  public searchString: string;
  public isLoading = false;
  public searching = false;

  constructor(
    public api: JsonapiService,
    private status: StatusService,
    private navCtrl: NavController,
    public loadingService: LoadingService,
    private modalController: ModalController,
    public notify: NotifyService,
    private translate: TranslateService

  ) {
  }

  ngOnInit() {
    this.status.setProductsOrder('name');
    this.searchString = '';
    this.search();
  }

  /*
    public refreshList() {
      if (!this.searching) {
        this.loadingService.start();
      }
      this.isLoading = true;
      this.api.getCollection(
        'applicator-products',
        this.status.getAppaltiProductsFilter(),
        this.status.getProductsOrder()
      ).subscribe((products: any) => {
        if (!this.searching) {
          this.loadingService.stop();
        }
        this.searching = false;
        this.isLoading = false;
        if (products && products.data) {
          this.items = products.data;
          const newAppalto = this.status.getNewAppalto();
          if (newAppalto && newAppalto.rows && newAppalto.rows.length) {
            this.selected = [];
            newAppalto.rows.forEach(row => {
              const found = this.items.find(x => x.id === row.id);
              if (found) { this.selected.push(found); }
              // TODO check se seleziona bene (non sono gli stessi oggetti)
            });
          }
        }
      }, (error) => { this.api.handlingError(error); });
    }
  */

  public refreshList(increment = false) {
    this.loadingService.start();
    this.isLoading = true;
    let page = 1;
    if (increment) {
      page = ++this.currentPage;
    } else {
      this.currentPage = page;
      this.calledGeneric = false;
      this.items = [];
      this.applicatorItems = [];
    }
    let type = 'applicator-products';

    if (this.calledGeneric) {
      type = 'products';
    }
    this.api.getCollectionPaginated(
      type,
      page,
      this.PAGE_SIZE,
      this.status.getAppaltiProductsFilter(),
      this.status.getProductsOrder()
    ).subscribe((products: any) => {
      if (products && products.data) {
        products.data.forEach(element => {
          if (type === 'applicator-products') {
            element.attributes.isApplicator = true;
            this.applicatorItems.push(element.attributes.sku);
            this.items.push(element);
          } else {
            if (this.applicatorItems.indexOf(element.attributes.sku) < 0) {
              this.items.push(element);
            }
          }
        });

        this.remaining = products.meta.page.total - products.meta.page.to;
        this.isLoading = false;
        if (!this.calledGeneric) {
          // Aggiunge i prodotti non associati all'applicator ( non acquistati o aggiunti )
          // if (this.remaining < 20) {
          //   this.calledGeneric = true;
          //   this.currentPage = 0;
          //   this.refreshList(true);
          // }
          // else {
          //   this.refreshList(true);
          // }
        }
        else {
          this.isLoading = false;
          this.loadingService.stop();
        }
        this.loadingService.stop();
      }
      else {
        this.isLoading = false;
        this.loadingService.stop();
      }
    }, (error) => { this.api.handlingError(error); });
  }


  search() {
    // console.log('search', this.searchString);
    this.status.setAppaltiProductsSearchString(this.searchString);
    this.searching = true;
    this.refreshList();
  }

  selectItem(item: any, checked: boolean) {
    if (checked) {
      if (!this.selected.find(x => x.id === item.id)) {
        this.selected.push(item);
      }
    } else {
      const itemIdx = this.selected.findIndex(x => x.id === item.id);
      this.selected.splice(itemIdx, 1);
    }
    this.selectedChange.emit(this.selected);
  }

  isSelected(item: any) {
    return this.selected.find(
      x => (x.id === item.id) ||
        (x.attributes.applicator_products_id === item.id && item.attributes.applicator_id)
    );
  }

  openProduct(item: any) {
    this.status.selectedProduct = item;
    this.navCtrl.navigateForward('prodotti-details');
  }

  openFilter() {
    this.modalController.create({
      component: ProdottiFilterComponent
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(res => {
        this.refreshList();
      });
    });
  }

  newProduct() {
    this.modalController.create({
      component: ProdottiNewModal
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(res => {
        if (res.data) {
          this.translate.get('PRODUCTS.suggestsent').subscribe((tran) => {
            this.notify.toast(tran, 'success');
          })
        }
      });
    });
  }

}
