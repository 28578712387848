
import { NgModule } from '@angular/core';
import { AppaltiItemComponent } from 'src/app/components/appalti-item/appalti-item.component';
import { ProdottiItemComponent } from 'src/app/components/prodotti-item/prodotti-item.component';
import { ClienteItemComponent } from 'src/app/components/cliente-item/cliente-item.component';
import { InvioItemComponent } from 'src/app/components/invio-item/invio-item.component';
import { SupportoItemComponent } from 'src/app/components/supporto-item/supporto-item.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { AppaltiEditnameComponent } from './appalti-editname/appalti-editname.component';
import { AppaltiEditproductsComponent } from './appalti-editproducts/appalti-editproducts.component';
import { AppaltiEditclientComponent } from './appalti-editclient/appalti-editclient.component';
import { ModalsModule } from '../modals/modals.modules';
import { ProdottiFilterComponent } from './prodotti-filter/prodotti-filter.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    entryComponents: [
        AppaltiItemComponent,
        ProdottiItemComponent,
        ClienteItemComponent,
        InvioItemComponent,
        SupportoItemComponent,
        AppaltiEditnameComponent,
        AppaltiEditproductsComponent,
        AppaltiEditclientComponent,
        ProdottiFilterComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        MomentModule,
        ModalsModule,
        TranslateModule.forChild()
    ],
    declarations: [
        AppaltiItemComponent,
        ProdottiItemComponent,
        ClienteItemComponent,
        InvioItemComponent,
        SupportoItemComponent,
        AppaltiEditnameComponent,
        AppaltiEditproductsComponent,
        AppaltiEditclientComponent,
        ProdottiFilterComponent
    ],
    exports: [
        AppaltiItemComponent,
        ProdottiItemComponent,
        ClienteItemComponent,
        InvioItemComponent,
        SupportoItemComponent,
        AppaltiEditnameComponent,
        AppaltiEditproductsComponent,
        AppaltiEditclientComponent,
        ProdottiFilterComponent
    ]
})
export class ComponentsModule { }
