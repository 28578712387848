import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-appalti-editname',
  templateUrl: './appalti-editname.component.html',
  styleUrls: ['./appalti-editname.component.scss'],
})
export class AppaltiEditnameComponent implements OnInit {

  public datePickerMin = new Date().toISOString();
  public datePickerMax = '';

  public monthShortNames = [];
  public monthNames = [];

  @Input() endDate;
  @Output() endDateChange = new EventEmitter<any>();

  @Input() name;
  @Output() nameChange = new EventEmitter<any>();

  constructor(
    private utils: UtilsService
  ) {
    const maxDate = new Date();
    maxDate.setFullYear(2100);
    this.datePickerMax = maxDate.toISOString();
  }

  ngOnInit() {
    this.monthShortNames = this.utils.getMonthShortNames();
    this.monthNames = this.utils.getMonthNames();
  }


  changeDate(event) {

  }


}
