import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class OauthService {

  API_BASE: string = environment[environment.env].oauth_base_url;
  CLIENT_ID = environment[environment.env].client_id;
  CLIENT_SECRET = environment[environment.env].client_secret;
  REDIRECT_URL = '';

  constructor(private httpClient: HttpClient) { }

  login(credentials) {
    return this.httpClient.post(`${this.API_BASE}/token`, {
      'grant_type': 'password',
      'client_id': this.CLIENT_ID,
      'client_secret': this.CLIENT_SECRET,
      'username': credentials.username,
      'password': credentials.password,
      "scope": ""
    });
  }

  refresh(token) {
    return this.httpClient.post(`${this.API_BASE}/token`, {
      'grant_type': 'refresh_token',
      'refresh_token': token,
      'client_id': this.CLIENT_ID,
      'client_secret': this.CLIENT_SECRET,
    });
  }

  getUser(token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers };
    return this.httpClient.get(`${environment[environment.env].base_url}/auth/user`, options);
  }

  logout(token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers };
    this.httpClient.get(`${environment[environment.env].auth_base_url}/logout`, options)
      .subscribe(
        () => { console.log('LOGOUT'); },
        () => { }
      );
  }

  register(credentials) {
    return this.httpClient.post(`${environment[environment.env].base_url}/register`, {
      'email': credentials.username,
      'password': credentials.password,
      "language": "it"
    });
  }

}
