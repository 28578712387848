import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/general/config';
import * as moment from 'moment';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { NavController, ModalController } from '@ionic/angular';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-prodotti-filtercomponent',
  templateUrl: './prodotti-filter.component.html',
  styleUrls: ['./prodotti-filter.component.scss'],
})
export class ProdottiFilterComponent implements OnInit {


  public selectedOrder;
  public orders = config.products.orders;

  @Output() exit = new EventEmitter<any>();

  constructor(
    private modalCtrl: ModalController,
    private status: StatusService) { }

  ngOnInit() {
    if (this.status.getProductsOrder()) {
      this.selectedOrder = this.status.getProductsOrder();
    }
  }

  back() {
    this.exit.emit();
    this.modalCtrl.dismiss();
  }

  cancel() {
    this.back();
  }

  apply() {
    this.status.setProductsOrder(this.selectedOrder);
    console.log('this.selectedOrder', this.selectedOrder)
    this.back();
  }

}
