import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable()
export class MessageService {
    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }
    filter(filterBy: object ) {
        this._listners.next(filterBy);
    }
    delete(event:string){    //the only thing that made sense to try so far
     /*  const FormList = this._listners.asObservable();

     // console.log(this._listners.observers.findIndex(a => a.event = event ));
      let target = this._listners.observers.findIndex(a => a.event = event );
      FormList.observers.unsubscribe(target); */
  }
}
