import { TokenService } from './token.service';
import { Event } from 'src/models/Event.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketService {
  private socket = null;

  constructor(
    private tokenservice: TokenService) {}

  public initSocket(): void {
    if (environment.socket) {
      const token = this.tokenservice.get();
      if (this.socket === null) {
        this.socket = socketIo(environment[environment.env].websocket,
          {
            query: `token=${token}&client=angular`
          });
      }
    }
  }

  public send(event: Event): void {
    this.socket.emit('event', event);
  }

  public onMessage(): Observable<Event> {
    if (environment.socket) {
      return new Observable<Event>(observer => {
        this.socket.on('event', (data: Event) => observer.next(data));
      });
    }
  }
}
