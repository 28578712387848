export const config = {
    timeFormat: {
        lastDay: '[Ieri]',
        sameDay: '[Oggi]',
        nextDay: '[Domani]',
        lastWeek: 'dddd [scorso]',
        nextWeek: 'dddd',
        sameElse: 'DD/MM/YY'
    },
    contracts: {
        selectStatus: [
            { value: '', text: 'CONTRACT.STATUS.all' },
            { value: 'ACCETTAZIONE', text: 'CONTRACT.STATUS.sent' },
            { value: 'CONSEGNA', text: 'CONTRACT.STATUS.delivered' },
            { value: 'AVVISO DI MANCATA CONSEGNA', text: 'CONTRACT.STATUS.noemail' },
            { value: 'NO PEC', text: 'CONTRACT.STATUS.nopec' },
            { value: 'NOT SENDEND', text: 'CONTRACT.STATUS.nodoc' }
        ],
        statuses: {
            /* 'INVIATA' : {
              class : '',
              text: `Inviato davvero`,
              error: false,
            }, */
            'ACCETTAZIONE': {
                class: '',
                text: `CONTRACT.STATUS.sent`,
                error: false,
            },
            'CONSEGNA': {
                class: 'greentxt',
                text: `CONTRACT.STATUS.delivered`,
                error: false,
            },
            'AVVISO DI MANCATA CONSEGNA': {
                class: 'redtxt',
                text: `CONTRACT.STATUS.noemail`,
                error: true,
            },
            'NO PEC': {
                class: 'redtxt',
                text: `CONTRACT.STATUS.nopec`,
                error: true,
            },
            'NOT SENDEND': {
                class: 'redtxt',
                text: `CONTRACT.STATUS.nodoc`,
                error: true,
            },
        },
        selectOrders: [
            { value: '-status', text: 'ORDER.status' },
            { value: '-updated-at', text: 'ORDER.updated' },
            { value: 'name', text: 'ORDER.name' }
        ]
    },
    products: {
        orders: [
            { value: 'name', text: 'ORDER.name' },
            { value: '-created-at', text: 'ORDER.created' }
        ],
    },
    clients: {
        orders: [
            { value: '-created-at', text: 'ORDER.created' },
            { value: 'rag_soc', text: 'ORDER.businessname' }
        ],
        types: [
            { value: '', text: 'CUSTOMER.TYPE.all' },
            { value: 'business', text: 'CUSTOMER.TYPE.business' },
            { value: 'privato', text: 'CUSTOMER.TYPE.private' }
        ],
    }
};
