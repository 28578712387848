import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { Zip } from '@ionic-native/zip/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DownloadService } from './Download.service';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {


    constructor(
        private downloadService: DownloadService,
        private file: File,
        public platform: Platform,
        private zip: Zip,
        private fileOpener: FileOpener,
        private loadingService: LoadingService
    ) { }

    public async openfile(productId, lang, type) {
        this.loadingService.start();
        this.downloadService.downloadFiles([parseInt(productId, 10)], lang, type).subscribe((file: any) => {
            const blob = new Blob([file], { type: 'application/octet-stream' });
            if (this.platform.is('mobile')) {
                this.openMobile(blob, productId, lang);
            } else {
                this.downloadBrowser(blob);
            }
        });

    }

    downloadBrowser(blob) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'download.zip';
        a.click();
        this.loadingService.stop();
    }

    async openMobile(blob, productId, lang) {
        try {
            const path = this.getPath();
            const downloadFilename = 'download.zip';
            await this.file.createFile(path, downloadFilename, true);
            await this.file.writeExistingFile(path, downloadFilename, blob);
            const dirname = `${productId}_${lang}`;
            const zipresult = await this.zip.unzip(path + downloadFilename, path + dirname);
            if (zipresult === 0) {
                const entries = await this.file.listDir(path, dirname);
                entries.forEach(entry => {
                    if (entry.isFile) {
                        // this.fileOpener.showOpenWithDialog(entry.nativeURL, 'application/pdf')
                        this.fileOpener.open(entry.nativeURL, 'application/pdf')
                            .then(() => console.log('opened file', entry))
                            .catch(e => console.log('Error opening file', e, entry));
                    }
                });
                this.loadingService.stop();
            } else { console.log('ZIP FAILED'); }
        } catch (e) {
            console.log('-----------: FileService -> openMobile -> error', e);
            this.loadingService.stop();
        }

    }

    getPath() {
        if (this.platform.is('android')) {
            return this.file.externalApplicationStorageDirectory;
        }
        return this.file.dataDirectory;
    }
}