import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // let status bar overlay webview
      this.statusBar.overlaysWebView(false);
      // set status bar to white
      this.statusBar.backgroundColorByHexString('#FFFFFF');
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.translate.setDefaultLang('en');
      const language = this.translate.getBrowserLang();
      this.translate.use(language);
      console.log('-----------: AppComponent -> initializeApp -> this.translate', this.translate);
      // this.translate.get('EXAMPLE').subscribe(trans => {
      //   console.log('-----------: TabAppaltiPage -> ngOnInit -> trans',  trans);
      // });
    });
  }
}
