import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Injectable()
export class UtilsService {

    private monthShortNames: string[] = [];
    private monthNames : string[] = [];

    constructor(
        public translate: TranslateService) {
        this.translate.get(['MONTH.1', 'MONTH.2', 'MONTH.3', 'MONTH.4', 'MONTH.5', 'MONTH.6', 'MONTH.7', 'MONTH.8', 'MONTH.9', 'MONTH.10', 'MONTH.11', 'MONTH.12',]).subscribe(t => {
            this.monthNames = [t['MONTH.1'], t['MONTH.2'], t['MONTH.3'], t['MONTH.4'], t['MONTH.5'], t['MONTH.6'], t['MONTH.7'], t['MONTH.8'], t['MONTH.9'], t['MONTH.10'], t['MONTH.11'], t['MONTH.12']]
        })
        this.translate.get(['SHORTMONTH.1', 'SHORTMONTH.2', 'SHORTMONTH.3', 'SHORTMONTH.4', 'SHORTMONTH.5', 'SHORTMONTH.6', 'SHORTMONTH.7', 'SHORTMONTH.8', 'SHORTMONTH.9', 'SHORTMONTH.10', 'SHORTMONTH.11', 'SHORTMONTH.12',]).subscribe(t => {
            this.monthShortNames = [t['SHORTMONTH.1'], t['SHORTMONTH.2'], t['SHORTMONTH.3'], t['SHORTMONTH.4'], t['SHORTMONTH.5'], t['SHORTMONTH.6'], t['SHORTMONTH.7'], t['SHORTMONTH.8'], t['SHORTMONTH.9'], t['SHORTMONTH.10'], t['SHORTMONTH.11'], t['SHORTMONTH.12']]
        })
    }

    public getMonthShortNames(){
        return this.monthShortNames;
    }

    public getMonthNames(){
        return this.monthNames;
    }


}
