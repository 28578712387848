import { Token } from '../../models/token.model';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';

@Injectable()
export class TokenService {

  token;
  constructor(private localStorage: LocalStorageService) {
    this.token = this.localStorage.get('token');
  }

  set(token) {
    this.token = token;
    this.localStorage.set('token', token);
  }

  get() {
    return this.token || this.localStorage.get('token');
  }

  destroy() {
    this.token = null;
    this.localStorage.remove('token');
  }

}
