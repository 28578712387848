import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-supporto-item',
  templateUrl: './supporto-item.component.html',
  styleUrls: ['./supporto-item.component.scss'],
})
export class SupportoItemComponent implements OnInit {

  @Input() label: string;

  constructor() {
  }

  ngOnInit() {
  }
}
