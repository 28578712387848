import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class NotifyService {


    constructor(private toastController: ToastController) { }

    public toast(msg, type = 'success') {
        this.toastController.create({
            message: msg,
            duration: 2000,
            color: type
        }).then(toast => {
            toast.present();
        });
    }
}