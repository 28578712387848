
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { ClienteNewModal } from './cliente-new/cliente-new.modal';
import { ClienteFilterModal } from './cliente-filter/cliente-filter.modal';
import { ProdottiNewModal } from './prodotti-new/prodotti-new.modal';
import { ContactsComponent } from './contacts/contacts.component';
import { TranslateModule } from '@ngx-translate/core';
import { TermsComponent } from './terms/terms.component';

@NgModule({
    entryComponents: [
        ClienteNewModal,
        ProdottiNewModal,
        ClienteFilterModal,
        ContactsComponent,
        TermsComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        TranslateModule.forChild()
    ],
    declarations: [
        ClienteNewModal,
        ProdottiNewModal,
        ClienteFilterModal,
        ContactsComponent,
        TermsComponent
    ],
    exports: [
        ClienteNewModal,
        ProdottiNewModal,
        ClienteFilterModal,
        ContactsComponent,
        TermsComponent
    ]
})
export class ModalsModule { }
