import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cliente-item',
  templateUrl: './cliente-item.component.html',
  styleUrls: ['./cliente-item.component.scss'],
})
export class ClienteItemComponent implements OnInit {

  @Input() item: any;
  @Input() selectable = false;

  @Output() detailClick = new EventEmitter();
  @Output() selectedChange = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  clickItem($event: any) {
    $event.preventDefault();
    if (this.selectable) {
      this.selectedChange.emit($event);
    }
    else {
    }
  }

  public open() {
    this.detailClick.emit();
  }

}
