export const utils = {
    isEmail: (email: string) => {
        // tslint:disable-next-line:max-line-length
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    isPiva: (piva: string) => {
        // tslint:disable-next-line:max-line-length
        const re = /^[0-9]{11}$/;
        return re.test(piva);
    },
    isEmptyText: (ragSoc) => {
        // tslint:disable-next-line:max-line-length
        const re = /^[\s]*$/;
        return re.test(ragSoc);
    }

};
