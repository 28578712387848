import { Component, OnInit, Input, Output } from '@angular/core';
import { config } from 'src/app/general/config';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-appalti-item',
  templateUrl: './appalti-item.component.html',
  styleUrls: ['./appalti-item.component.scss'],
})
export class AppaltiItemComponent implements OnInit {

  @Input() item: any;

  public timeFormat = config.timeFormat;
  public timeLocale = 'en';
  public sentStatus = '';

  constructor(
    private translate: TranslateService
  ) { }

  public dateFrom(d) {
    return moment(d).toDate();
  }

  ngOnInit() {
    this.timeLocale = this.translate.currentLang;
    if ((this.item) && (this.item.attributes) && (this.item.attributes.sents) && (this.item.attributes.sents.length)) {
      this.sentStatus = this.item.attributes.sents[this.item.attributes.sents.length - 1].status;
    }
    if (!this.sentStatus) {
      this.sentStatus = '';
    }
  }

}
