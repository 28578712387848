import { Component } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { EventService } from 'src/app/services/event.service';
import { StatusService } from 'src/app/services/status.service';
import { NavController } from '@ionic/angular';
import { favoritesService } from 'src/app/services/favorites.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-tabPreferiti',
  templateUrl: 'tabPreferiti.page.html',
  styleUrls: ['tabPreferiti.page.scss']
})
export class TabPreferitiPage {

  public selectedType = 'prodotti';

  public appalti = [];
  public prodotti = [];


  public isLoading = false;


  constructor(
    public api: JsonapiService,
    public events: EventService,
    private status: StatusService,
    private navCtrl: NavController,
    private favoriteService: favoritesService,
    public loadingService: LoadingService
  ) {
    events.changeTabEvent().subscribe(tab => {
      console.log('-----------: tabPreferiti -> tab', tab);
      if (tab === events.TAB_PREFERITI) {
        this.refreshList();
      }
    });
  }

  ionViewWillEnter() {
    this.refreshList();
  }

  public refreshList(increment = false) {

    this.isLoading = true;
    this.loadingService.start();

    this.favoriteService.getAllfavorites('products').then(prods => {
      this.prodotti = prods;
      this.isLoading = false;
      this.loadingService.stop();
    }, (error) => {
      this.loadingService.stop();
    });

    this.favoriteService.getAllfavorites('contracts').then(contracts => {
      this.appalti = contracts;
    });

    // // TODO remove 
    // this.api.getCollectionPaginated(
    //   'contracts'
    // ).subscribe((resp: any) => {
    //   this.appalti = resp.data;
    // });
    

  }

  openAppalto(item) {
    this.status.selectedContract = item;
    this.navCtrl.navigateForward('appalti-details');
  }

  openProdotto(item) {
    this.status.selectedProduct = item;
    this.navCtrl.navigateForward('prodotti-details');
  }

  removefavorite(item) {
    console.log('item', item);
    this.favoriteService.setfavorite('products', item.id, false).then(() => {
      console.log('done');
      this.refreshList();
    });
  }

}
