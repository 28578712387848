import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { of, Subject } from 'rxjs';
import { Contract, ContractData } from 'src/models/contract.model';
import { Product, ProductEntity } from 'src/models/product.model';
import { Customer, CustmerData } from 'src/models/customer.model';
import { IUser, JsonApiData } from 'src/models/user.model';
import { JsonapiService } from './jsonapi.service';
import { StatusService } from './status.service';



@Injectable()
export class favoritesService {

    private favoriteProductsCache = [];
    private favoriteContractsCache = [];

    constructor(
        public api: JsonapiService,
        private status: StatusService) { }

    private async refreshProductsCache() {
        const coll: any = await this.api.getCollection('favorites').toPromise();
        this.favoriteProductsCache = [];
        coll.data.forEach((element: any) => {
            const product = {
                id: element.attributes.product_id,
                attributes: element.attributes.product,
                deleteId: element.id
            }
            this.favoriteProductsCache.push(product);
        });
    }

    private async refreshContractsCache() {
        // TODO unlock refresh
        // const coll = await this.api.getCollection('favorites');
        // this.favoriteContractsCache = [];
        // coll.forEach(element => {
        //     console.log('-----------: favoritesService -> getContracts -> element', element);
        //     this.favoriteContractsCache.push(element);
        // });
    }

    private async getProducts() {
        if (!this.favoriteProductsCache || !this.favoriteProductsCache.length) {
            await this.refreshProductsCache();
        }
        return this.favoriteProductsCache;
    }



    private async getContracts() {
        if (!this.favoriteContractsCache || !this.favoriteContractsCache.length) {
            await this.refreshContractsCache();
        }
        return this.favoriteContractsCache;

    }

    async getAllfavorites(type: string) {
        if (type === 'products') {
            await this.refreshProductsCache();

            return this.favoriteProductsCache;
        } else {
            await this.refreshContractsCache();
            return this.favoriteContractsCache;
        }
    }

    async isfavorite(type: string, id: string) {
        if (type === 'products') {
            const res = this.favoriteProductsCache.find(x => {
                console.log('-----------: favoritesService -> isfavorite -> x.attributes.id', x.attributes.id, id);
                return x.attributes.id.toString() === id.toString();
            });
            console.log('-----------: favoritesService -> isfavorite -> res', res);
            return res;
        } else {
            return this.favoriteContractsCache.find(x => x.attributes.id === id);
        }

    }

    async  setfavorite(favType: string, id: string, isfavorite: boolean) {

        // let favId = 0;
        // if (favType === 'products') {
        // } else {
        //     favId = (await this.getContracts()).find(x => x.attributes.contract_id === id);
        // }

        if (isfavorite) {
            const user: JsonApiData = await this.status.currentUser().toPromise();

            const data = {
                data: {
                    type: 'favorites',
                    attributes: {
                        applicator_id: user.attributes.ref_id,
                        product_id: id
                    }
                }
            };
            return await this.api.postEntity('favorites', data).toPromise();
        } else {
            const {deleteId} = (await this.getProducts()).find(x => x.attributes.id.toString() === id.toString());
            return await this.api.deleteEntity('favorites', deleteId).toPromise();
        }
    }

}
