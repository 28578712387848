import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    public TAB_APPALTO = 'tabAppalto';
    public TAB_PRODOTTI = 'tabProdotti';
    public TAB_PREFERITI = 'tabPreferiti';
    public TAB_NOTIFICHE = 'tabNotifiche';

    private tabSubject = new Subject<any>();

    changeTab(data: any) {
        this.tabSubject.next(data);
    }

    changeTabEvent(): Subject<any> {
        return this.tabSubject;
    }
}