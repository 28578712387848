import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { IUser, JsonApiData } from 'src/models/user.model';
import { NotifyService } from 'src/app/services/notify.service';
import { StatusService } from 'src/app/services/status.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  close(sent = false) {
    this.modalCtrl.dismiss(sent);
  }

}
