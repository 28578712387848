import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/tabAppalti',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'registry',
    loadChildren: () => import('./pages/registry/registry.module').then(m => m.RegistryPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expired',
    loadChildren: () => import('./pages/expired/expired.module').then(m => m.ExpiredPageModule)
  },
  {
    path: 'recover',
    loadChildren: () => import('./pages/recover/recover.module').then(m => m.RecoverPageModule)
  },
  {
    path: 'recover-otp',
    loadChildren: () => import('./pages/recover-otp/recover-otp.module').then(m => m.RecoverOtpPageModule)
  },
  {
    path: 'recover-new',
    loadChildren: () => import('./pages/recover-new/recover-new.module').then(m => m.RecoverNewPageModule)
  },
  {
    path: 'new-appalto1',
    loadChildren: () => import('./pages/appaltoNew1/new-appalto1.module').then(m => m.NewAppalto1PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-appalto2',
    loadChildren: () => import('./pages/appaltoNew2/new-appalto2.module').then(m => m.NewAppalto2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-appalto3',
    loadChildren: () => import('./pages/appaltoNew3/new-appalto3.module').then(m => m.NewAppalto3PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-appalto4',
    loadChildren: () => import('./pages/appaltoNew4/new-appalto4.module').then(m => m.NewAppalto4PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appalti-details',
    loadChildren: () => import('./pages/appalti-details/appalti-details.module').then(m => m.AppaltiDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appalti-filter',
    loadChildren: () => import('./pages/appalti-filter/appalti-filter.module').then(m => m.AppaltiFilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'prodotti-filter',
    loadChildren: () => import('./pages/prodotti-filter/prodotti-filter.module').then(m => m.ProdottiFilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'prodotti-details',
    loadChildren: () => import('./pages/prodotti-details/prodotti-details.module').then(m => m.ProdottiDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profilo-edit',
    loadChildren: () => import('./pages/profilo-edit/profilo-edit.module').then(m => m.ProfiloEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supporto',
    loadChildren: () => import('./pages/supporto/supporto.module').then(m => m.SupportoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supporto-content',
    loadChildren: () => import('./pages/supporto-content/supporto-content.module').then(m => m.SupportoContentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appalti-edit',
    loadChildren: () => import('./pages/appalti-edit/appalti-edit.module').then(m => m.AppaltiEditPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
