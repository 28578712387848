import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { of, Subject } from 'rxjs';
import { Contract, ContractData } from 'src/models/contract.model';
import { Product, ProductEntity } from 'src/models/product.model';
import { Customer, CustmerData } from 'src/models/customer.model';
import { IUser, JsonApiData } from 'src/models/user.model';
import { utils } from 'src/app/general/utils';

export interface ContractFilter {
    status?: any;
    order?: any;
    search?: string;
}

export interface ProductstFilter {
    order?: any;
    search?: string;
}

export interface CustomerFilter {
    customer_type?: any;
    order?: any;
    search?: string;
}


@Injectable()
export class StatusService {

    private contractFilter: ContractFilter = {};
    private contractFilterSubject = new Subject();
    private productstFilter: ProductstFilter = {
        order: 'name'
    };
    private contractsProductstFilter: ProductstFilter = {
        order: 'name'
    };
    private contractsCustomerFilter: CustomerFilter = {
        order: 'rag_soc'
    };
    private user: JsonApiData;

    private appaltoNew: Contract;
    private appaltoNewCustomer: CustmerData;
    public appaltiEdit = 'name';

    public selectedContract: ContractData;
    public selectedProduct: ProductEntity;
    public selectedClient: CustmerData;

    public faqTitle = '';
    public faqContent = '';


    constructor(
        public auth: AuthService,) { }

    private arrayOf(val) {
        const ret = [];
        for (const i in val) {
            if (val.hasOwnProperty(i) && i !== 'order') {
                ret.push({ name: i, value: val[i] });
            }
        }
        return ret;
    }

    updateContractFilter() {
        return this.contractFilterSubject;
    }

    setContractFilter(status, order) {
        this.contractFilter.status = status;
        this.contractFilter.order = order;
        this.contractFilterSubject.next('');
    }

    setContractFilterString(searchString: string) {
        if (!utils.isEmptyText(searchString)) {
            this.contractFilter.search = searchString;
        }
        else {
            delete this.contractFilter.search;
        }
        this.contractFilterSubject.next('');
    }

    getContractFilter() {
        return this.arrayOf(this.contractFilter);
    }

    getContractFilterStatus() {
        return this.contractFilter.status;
    }

    getContractOrder() {
        return this.contractFilter.order;
    }


    setProductsOrder(order) {
        this.productstFilter.order = order;
    }

    getProductsOrder() {
        return this.productstFilter.order;
    }

    setProductsSearchString(val) {
        this.productstFilter.search = val;
    }

    getProductsSearchString() {
        return this.productstFilter.search;
    }

    getProductsFilter() {
        return this.arrayOf(this.productstFilter);
    }

    getAppaltiProductsFilter() {
        return this.arrayOf(this.contractsProductstFilter);
    }

    getAppaltiProductsOrder() {
        return this.contractsProductstFilter.order;
    }

    setAppaltiProductsSearchString(val: string) {
        if (!utils.isEmptyText(val)) {
            this.contractsProductstFilter.search = val;
        }
        else {
            delete this.contractsProductstFilter.search;
        }
    }

    getAppaltiProductsSearchString() {
        return this.contractsProductstFilter.search;
    }


    getAppaltiClientFilter() {
        return this.arrayOf(this.contractsCustomerFilter);
    }
    getAppaltiClientOrder() {
        return this.contractsCustomerFilter.order;
    }
    setAppaltiClientOrder(order) {
        this.contractsCustomerFilter.order = order;
    }
    getAppaltiClientType() {
        return this.contractsCustomerFilter.customer_type;
    }
    setAppaltiClientType(customer_type) {
        if (!utils.isEmptyText(customer_type)) {
            this.contractsCustomerFilter.customer_type = customer_type;
        }
        else {
            delete this.contractsCustomerFilter.customer_type;
        }
    }
    setAppaltiCustomerSearchString(val: string) {
        if (!utils.isEmptyText(val)) {
            this.contractsCustomerFilter.search = val;
        }
        else {
            delete this.contractsCustomerFilter.search;
        }
    }

    getAppaltiCustomerSearchString() {
        return this.contractsCustomerFilter.search;
    }

    currentUser() {
        if (this.user) {
            return of(this.user);
        } else {
            return this.auth.getUser().map((usr: IUser) => {
                this.user = usr.data;
                return this.user;
            });
        }
    }

    resetUser() {
        this.user = null;
    }

    createNewAppalto(nome, dataFine) {
        this.appaltoNew = {
            name: nome,
            dta_end_of_works: dataFine,
            sended: 0,
            status: '',
            customer: '',
            customer_id: 0,
            last_mail_status: '',
            rows: [],
            sents: [],
            'created-at': '',
            'updated-at': '',
        };
    }

    initNewAppalto(item: any) {
        const rows = [];
        item.attributes.rows.forEach(row => {
            rows.push({
                type: 'applicator-products',
                id: row.applicator_products_id,
                attributes: row
            });
        });

        this.appaltoNew = {
            name: item.attributes.name,
            dta_end_of_works: item.attributes.dta_end_of_works,
            sended: item.attributes.sended,
            status: item.attributes.status,
            customer: item.attributes.customer,
            customer_id: item.attributes.customer_id,
            last_mail_status: item.attributes.last_mail_status,
            rows: rows,
            sents: item.attributes.sents,
            'created-at': item.attributes['created-at'],
            'updated-at': item.attributes['updated-at'],
        };
    }

    getNewAppalto() {
        return this.appaltoNew;
    }

    setNewAppaltoName(name: string) {
        this.appaltoNew.name = name;
    }

    setNewAppaltoDate(date: string) {
        this.appaltoNew.dta_end_of_works = date;
    }

    setNewAppaltoProducts(products: any[]) {
        this.appaltoNew.rows = products;
    }

    setNewAppaltoCliente(customer) {
        this.appaltoNewCustomer = customer;
        this.appaltoNew.customer_id = customer.id;

    }

    getNewAppaltoCliente() {
        return this.appaltoNewCustomer;

    }

    clearNewAppalto() {
        this.appaltoNew = null;
        this.appaltoNewCustomer = null;
    }

}
