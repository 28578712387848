import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { StatusService } from 'src/app/services/status.service';
import { utils } from 'src/app/general/utils';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NotifyService } from 'src/app/services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cliente-new',
  templateUrl: './cliente-new.modal.html',
  styleUrls: ['./cliente-new.modal.scss'],
})
export class ClienteNewModal implements OnInit {


  public tipo = 'business';
  public ragSoc;
  public piva;
  public comune;
  public tipoMail = 'pec';
  public email;
  public note;

  clientForm: FormGroup;
  validationMessages = {
    tipo: [
      { type: 'required', message: 'CUSTOMERS.tipo_required' },
    ],
    ragSoc: [
      { type: 'required', message: 'CUSTOMERS.ragSoc_required' },
    ],
    piva: [
      { type: 'required', message: 'CUSTOMERS.piva_required' },
      { type: 'pattern', message: 'CUSTOMERS.piva_pattern' }
    ],
    email: [
      { type: 'required', message: 'CUSTOMERS.email_required' },
      { type: 'pattern', message: 'CUSTOMERS.email_pattern' }
    ],
    comune: [
      { type: 'required', message: 'CUSTOMERS.comune_required' },
    ],
    tipoMail: [
      { type: 'required', message: 'CUSTOMERS.tipoMail_required' },
    ],
  };


  public emailError = false;

  constructor(
    private modalCtrl: ModalController,
    private api: JsonapiService,
    private status: StatusService,
    public formBuilder: FormBuilder,
    private notify: NotifyService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.status.selectedClient) {
      // console.log('this.status.selectedClient', this.status.selectedClient);
      this.tipo = this.status.selectedClient.attributes.customer_type;
      this.ragSoc = this.status.selectedClient.attributes.rag_soc;
      this.piva = this.status.selectedClient.attributes.piva;
      this.comune = this.status.selectedClient.attributes.comune;
      this.tipoMail = this.status.selectedClient.attributes.emailtype;
      this.email = this.status.selectedClient.attributes.email;
      this.note = this.status.selectedClient.attributes.note;
    }
    this.clientForm = this.formBuilder.group({
      tipo: new FormControl(this.tipo, Validators.required),
      ragSoc: new FormControl(this.ragSoc, Validators.required),
      piva: new FormControl(this.piva, Validators.compose([
        Validators.required,
        // Validators.pattern('^[0-9]{11}$')
        Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
      ])),
      comune: new FormControl(this.comune, Validators.required),
      tipoMail: new FormControl(this.tipoMail, Validators.required),
      email: new FormControl(this.email, Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$')
        Validators.pattern('^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')
      ])),
      note: new FormControl(this.note)
    });
  }

  isValid() {
    this.emailError = true;
    return !utils.isEmptyText(this.ragSoc)
      && utils.isPiva(this.piva)
      // && this.comune
      && utils.isEmail(this.email);
  }

  tipoChanged($event) {
    // console.log('-----------: ClienteNewModal -> tipoChanged -> $event', $event);
  }

  isEdit(){
    return  this.status.selectedClient && this.status.selectedClient.id;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit(obj: any) {
    this.validateAllFormFields(this.clientForm);
    // console.log('obj', obj);
    if (this.clientForm.valid) {
      const clientObj = {
        data:
        {
          type: 'customers',
          attributes: {
            rag_soc: obj.ragSoc,
            piva: obj.piva,
            pec: obj.email,
            email: obj.email,
            emailtype: obj.tipoMail,
            customer_type: obj.tipo,
            comune: obj.comune,
            note: obj.note,
          }
        }
      };
      if (this.status.selectedClient) {
        clientObj.data['id'] = this.status.selectedClient.id;
        this.api.updateEntity('customers', this.status.selectedClient.id, clientObj).subscribe((saveRes:any) => {
          this.close(saveRes.data);
        }, (error) => { this.api.handlingError(error); });
  
      } else {
        this.api.postEntity('customers', clientObj).subscribe((saveRes:any) => {
          this.close(saveRes.data);
        }, (error) => {
          this.api.handlingError(error);
        });
      }
    }
  }

  add() {
    const clientObj = {
      data:
      {
        type: 'customers',
        attributes: {
          rag_soc: this.ragSoc,
          piva: this.piva,
          pec: this.email,
          customer_type: this.tipo,
          comune: this.comune
        }
      }
    };
    if (this.status.selectedClient) {
      clientObj.data['id'] = this.status.selectedClient.id;
      this.api.updateEntity('customers', this.status.selectedClient.id, clientObj).subscribe(saveRes => {
        this.close(true);
      }, (error) => { this.api.handlingError(error); });

    } else {
      this.api.postEntity('customers', clientObj).subscribe(saveRes => {
        this.close(true);
      }, (error) => { this.api.handlingError(error); });
    }

  }

  close(sent = false) {
    this.modalCtrl.dismiss(sent);
  }

}
